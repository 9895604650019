/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui"
import Btn from "./button"

const CTA = ({ children }) => (
  <div>
    <h2
      sx={{
        fontSize: 4,
        fontWeight: "bold",
        fontFamily: "heading",
        lineHeight: "heading",
      }}
    >
      {children}
    </h2>
    <div sx={{mt: 4}}>
      <Btn/>
    </div>
  </div>
)

export default CTA
