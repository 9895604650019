/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, BaseStyles } from "theme-ui"
// import * as React from "react";
import Layout from "../components/layout"
import Contain from "../components/contain"
import Stack from "../components/stack"
import CTA from '../components/cta'
import SEO from '../components/seo'
import PageTitle from '../components/pageTitle'
import Headline from '../components/editorial-headline'
import ThicLine from '../components/editorial-thicline'

// markup
const OpCenterPage = () => {
  return (
    <Layout>
      
      {/* Editorial Headline */}
      <Contain mw={"col10"}>
        <SEO title="Operations Centers" />
        <div sx={{mb:4}}>
          <PageTitle name="Operations Centers" />
        </div>
        <div sx={{mb: 5}}>
          <Headline>Cubeless in the Cube</Headline>
          <ThicLine/>
        </div>      
        </Contain>
        
        <Contain mw={'col6'}>
          <BaseStyles>
            
            
            <Stack gap={4}>
                
              <Stack gap={3}>
                <h3>Cubeless isn't the alternative to operation center security &mdash; it's the cornerstone.</h3>

                <p>Suprised? You shouldn't be.</p>

                <p>The Cubeless security platform is so good it's the foundation of our on-premise solution as well. You get the same industry-leading platform whether your project is remote or in one of our <a sx={{color: 'hotRed'}} href="https://modsquad.com/connect/" target="_blank" rel="noreferrer">operations centers</a>.</p>

                <p>In addition to <Link sx={{color: 'hotRed'}} to="/about">the Cubless Platform</Link>, operations centers offer a layer of physical security including keyed entry, device lockers, video monitoring, and on-premise supervision.</p>
                </Stack>
                
                <Stack gap={3}>
                <h3>Enjoy true business continuity</h3>
                <p>True business continuity means keeping your CX services up and running no matter where, no matter what.</p>
                <p>Because our op centers run on Cubeless, changing security locations is seamless. So if disaster strikes, your Mods will stay up and running and remain secure. Switch services between op centers or take them remote without scrambling, without scary migrations, without hair-on-fire moments.</p>
                </Stack>
            
            </Stack>
            </BaseStyles>
            <div sx={{mt: 4}}>
              <CTA>
                  So ops center or remote?<br/>
                  Let's figure out what you need.
              </CTA>
            </div>
        
        </Contain>
    </Layout>
  )
}

export default OpCenterPage
