/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx } from 'theme-ui'

export default props => (
    <div sx={{
      display: 'grid',
      // flexDirection: 'column',
      gap: props.gap,
    }}>
      {props.children}
    </div>
  )